<script setup>
import {computed, ref} from 'vue';
import {Link, usePage} from '@inertiajs/vue3';
import route from "@/Helpers/route";
import Dropdown from "@/Components/Website/Dropdown.vue";

const page = usePage();

const props = defineProps({
    restaurant: Object,
});

const showingNavigationDropdown = ref(false);

const destination = computed(() => {
    if (! props.restaurant.name || ! props.restaurant.address) {
        return '';
    }

    let dest = `${props.restaurant.name}, ${props.restaurant.address.street} ${props.restaurant.address.number}`;

    return encodeURIComponent(dest);
});
</script>

<template>
    <header>
        <nav class="bg-[var(--primary)] py-3">
            <div class="w-full max-w-[1800px] flex flex-col xl:flex-row xl:justify-between gap-2 xl:gap-4 mx-auto px-4 sm:px-6 lg:px-8">

                <div class="flex flex-row justify-between gap-6">
                    <a :href="`mailto:${restaurant.email}`" class="hidden xl:flex flex-row gap-2 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" class="fill-white size-4">
                            <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/>
                        </svg>

                        <span class="text-xs xl:text-sm text-white font-medium">{{ restaurant.email }}</span>
                    </a>

                    <a :href="`tel:${restaurant.phone}`" class="flex flex-row gap-2 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" class="fill-white size-4">
                            <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/>
                        </svg>

                        <span class="text-xs xl:text-sm text-white font-medium">{{ restaurant.phone }}</span>
                    </a>

                    <a :href="`https://www.google.com/maps/dir/?api=1&destination=${destination}`" class="flex flex-row gap-2 items-center" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" class="fill-white size-4">
                            <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path d="M36.8 192H603.2c20.3 0 36.8-16.5 36.8-36.8c0-7.3-2.2-14.4-6.2-20.4L558.2 21.4C549.3 8 534.4 0 518.3 0H121.7c-16 0-31 8-39.9 21.4L6.2 134.7c-4 6.1-6.2 13.2-6.2 20.4C0 175.5 16.5 192 36.8 192zM64 224V384v80c0 26.5 21.5 48 48 48H336c26.5 0 48-21.5 48-48V384 224H320V384H128V224H64zm448 0V480c0 17.7 14.3 32 32 32s32-14.3 32-32V224H512z"/>
                        </svg>

                        <address class="text-xs xl:text-sm text-white font-medium not-italic">
                            {{ restaurant.address.street }} {{ restaurant.address.number }}<template v-if="restaurant.address.extra"> {{ restaurant.address.extra }}</template>, <wbr>
                            {{ restaurant.address.zipcode }} {{ restaurant.address.city }}
                        </address>
                    </a>
                </div>

                <div class="flex flex-row justify-center xl:justify-normal gap-2">
                    <a :href="link.url" target="_blank" class="font-medium text-sky-500" v-for="link in restaurant.custom_links" :key="link.id" rel="nofollow">
                        <img src="/images/social-media-white/discord-square.svg" :alt="`${restaurant.name} Discord`" class="fill-white size-6 aspect-square" v-if="link.url.includes('discord')">
                        <img src="/images/social-media-white/facebook-square.svg" :alt="`${restaurant.name} Facebook`" class="fill-white size-6 aspect-square" v-else-if="link.url.includes('facebook')">
                        <img src="/images/social-media-white/instagram-square.svg" :alt="`${restaurant.name} Instagram`" class="fill-white size-6 aspect-square" v-else-if="link.url.includes('instagram')">
                        <img src="/images/social-media-white/kvk-square.svg" :alt="`${restaurant.name} KVK`" class="fill-white size-6 aspect-square" v-else-if="link.url.includes('kvk')">
                        <img src="/images/social-media-white/linkedin-square.svg" :alt="`${restaurant.name} LinkedIn`" class="fill-white size-6 aspect-square" v-else-if="link.url.includes('linkedin')">
                        <img src="/images/social-media-white/medium-square.svg" :alt="`${restaurant.name} Medium`" class="fill-white size-6 aspect-square" v-else-if="link.url.includes('medium')">
                        <img src="/images/social-media-white/medium-square.svg" :alt="`${restaurant.name} Pinterest`" class="fill-white size-6 aspect-square" v-else-if="link.url.includes('pinterest')">
                        <img src="/images/social-media-white/reddit-square.svg" :alt="`${restaurant.name} Reddit`" class="fill-white size-6 aspect-square" v-else-if="link.url.includes('reddit')">
                        <img src="/images/social-media-white/snapchat-square.svg" :alt="`${restaurant.name} Snapchat`" class="fill-white size-6 aspect-square" v-else-if="link.url.includes('snapchat')">
                        <img src="/images/social-media-white/soundcloud-square.svg" :alt="`${restaurant.name} SoundCloud`" class="fill-white size-6 aspect-square" v-else-if="link.url.includes('soundcloud')">
                        <img src="/images/social-media-white/telegram-square.svg" :alt="`${restaurant.name} Telegram`" class="fill-white size-6 aspect-square" v-else-if="link.url.includes('telegram')">
                        <img src="/images/social-media-white/tiktok-square.svg" :alt="`${restaurant.name} TikTok`" class="fill-white size-6 aspect-square" v-else-if="link.url.includes('tiktok')">
                        <img src="/images/social-media-white/tripadvisor-square.svg" :alt="`${restaurant.name} Tripadvisor`" class="fill-white size-6 aspect-square" v-else-if="link.url.includes('tripadvisor')">
                        <img src="/images/social-media-white/tumblr-square.svg" :alt="`${restaurant.name} Tumblr`" class="fill-white size-6 aspect-square" v-else-if="link.url.includes('tumblr')">
                        <img src="/images/social-media-white/twitch-square.svg" :alt="`${restaurant.name} Twitch`" class="fill-white size-6 aspect-square" v-else-if="link.url.includes('twitch')">
                        <img src="/images/social-media-white/twitter-square.svg" :alt="`${restaurant.name} Twitter`" class="fill-white size-6 aspect-square" v-else-if="link.url.includes('twitter')">
                        <img src="/images/social-media-white/whatsapp-square.svg" :alt="`${restaurant.name} WhatsApp`" class="fill-white size-6 aspect-square" v-else-if="link.url.includes('whatsapp')">
                        <img src="/images/social-media-white/youtube-square.svg" :alt="`${restaurant.name} YouTube`" class="fill-white size-6 aspect-square" v-else-if="link.url.includes('youtube')">
                        <template v-else>{{ link.text }}</template>
                    </a>
                </div>
            </div>
        </nav>

        <nav class="bg-white shadow py-3">
            <div class="w-full max-w-[1800px] flex flex-row justify-between gap-4 mx-auto px-4 sm:px-6 lg:px-8">
                <Link href="/">
                    <img :src="restaurant.links.logo" :srcset="restaurant.links.responsive_logo" :alt="`Logo ${restaurant.name}`" class="block max-w-48 max-h-24">
                </Link>

                <div class="mx-auto"></div>

                <template v-if="page.props.extra.header">
                    <nav class="hidden xl:flex flex-row gap-8 items-center">
                        <template v-for="link in page.props.extra.header.nav_menu_items" :key="link.id">
                            <template v-if="link.type === 'link'">
                                <Link :href="link.url" class="text-lg font-medium" v-if="!link.url.startsWith('http')">{{ link.text }}</Link>
                                <a :href="link.url" class="text-lg font-medium" v-if="link.url.startsWith('http')" target="_blank">{{ link.text }}</a>
                            </template>

                            <template v-else-if="link.type === 'dropdown'">
                                <Dropdown>
                                    <template #trigger>
                                        <a class="block text-lg font-medium" href="#">{{ link.text }}</a>
                                    </template>

                                    <template #content>
                                        <div class="flex flex-col divide-y">
                                            <template v-for="link in link.nav_menu_items" :key="link.id">
                                                <Link :href="link.url" class="font-medium hover:bg-gray-50 p-2" v-if="!link.url.startsWith('http')">{{ link.text }}</Link>
                                                <a :href="link.url" class="font-medium hover:bg-gray-50 p-2" v-if="link.url.startsWith('http')" target="_blank">{{ link.text }}</a>
                                            </template>
                                        </div>
                                    </template>
                                </Dropdown>
                            </template>
                        </template>
                    </nav>
                </template>

                <template v-if="!page.props.extra.header">
                    <nav class="hidden xl:flex flex-row gap-8 items-center">
                        <Link href="/" class="text-lg font-medium">Homepagina</Link>
                        <Link :href="route('website.menu', { restaurant })" class="text-lg font-medium" v-if="restaurant.can_deliver || restaurant.can_pickup">Bestellen</Link>
                        <Link :href="route('website.reservations.create', { restaurant })" class="text-lg font-medium" v-if="restaurant.accepts_reservations">Reserveren</Link>
                        <Link :href="route('website.news', { restaurant })" class="text-lg font-medium" v-if="restaurant.news_count > 0">Nieuws</Link>
                        <Link :href="`/${page.slug}`" class="text-lg font-medium" v-for="page in page.props.extra.pages" :key="page.id">{{ page.name }}</Link>
                        <Link :href="route('website.gallery', { restaurant })" class="text-lg font-medium">Galerij</Link>
                        <Link :href="route('website.info', { restaurant })" class="text-lg font-medium">Informatie</Link>
                        <Link :href="route('website.contact.create', { restaurant })" class="text-lg font-medium">Contact formulier</Link>
                    </nav>
                </template>

                <div class="xl:hidden self-center">
                    <div class="flex items-center">
                        <button @click="showingNavigationDropdown = ! showingNavigationDropdown" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                            <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path :class="{'hidden': showingNavigationDropdown, 'inline-flex': ! showingNavigationDropdown }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                                <path :class="{'hidden': ! showingNavigationDropdown, 'inline-flex': showingNavigationDropdown }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                            <span class="sr-only">{{ showingNavigationDropdown ? 'Sluit navigatie menu' : 'Open navigatie menu' }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </nav>

        <template v-if="page.props.extra.header">
            <div :class="{'block': showingNavigationDropdown, 'hidden': ! showingNavigationDropdown}" class="bg-white xl:hidden">
                <div class="flex flex-col divide-y border-y">
                    <template v-for="link in page.props.extra.header.nav_menu_items" :key="link.id">
                        <template v-if="link.type === 'link'">
                            <Link :href="link.url" class="hover:bg-gray-100 font-medium px-4 py-2" v-if="!link.url.startsWith('http')">{{ link.text }}</Link>
                            <a :href="link.url" class="hover:bg-gray-100 font-medium px-4 py-2" v-if="link.url.startsWith('http')" target="_blank">{{ link.text }}</a>
                        </template>

                        <template v-else-if="link.type === 'dropdown'">
                            <template v-for="link in link.nav_menu_items" :key="link.id">
                                <Link :href="link.url" class="hover:bg-gray-100 font-medium px-4 py-2" v-if="!link.url.startsWith('http')">{{ link.text }}</Link>
                                <a :href="link.url" class="hover:bg-gray-100 font-medium px-4 py-2" v-if="link.url.startsWith('http')" target="_blank">{{ link.text }}</a>
                            </template>
                        </template>
                    </template>
                </div>
            </div>
        </template>

        <template v-if="!page.props.extra.header">
            <div :class="{'block': showingNavigationDropdown, 'hidden': ! showingNavigationDropdown}" class="bg-white xl:hidden">
                <div class="flex flex-col">
                    <Link href="/" class="hover:bg-gray-100 text-lg font-medium px-4 py-2">Homepagina</Link>
                    <Link :href="route('website.menu', { restaurant })" class="hover:bg-gray-100 text-lg font-medium px-4 py-2" v-if="restaurant.can_deliver || restaurant.can_pickup">Bestellen</Link>
                    <Link :href="route('website.reservations.create', { restaurant })" class="hover:bg-gray-100 text-lg font-medium px-4 py-2" v-if="restaurant.accepts_reservations">Reserveren</Link>
                    <Link :href="route('website.news', { restaurant })" class="hover:bg-gray-100 text-lg font-medium px-4 py-2" v-if="restaurant.news_count > 0">Nieuws</Link>
                    <Link :href="`/${page.slug}`" class="hover:bg-gray-100 text-lg font-medium px-4 py-2" v-for="page in page.props.extra.pages" :key="page.id">{{ page.name }}</Link>
                    <Link :href="route('website.gallery', { restaurant })" class="hover:bg-gray-100 text-lg font-medium px-4 py-2">Galerij</Link>
                    <Link :href="route('website.info', { restaurant })" class="hover:bg-gray-100 text-lg font-medium px-4 py-2">Informatie</Link>
                    <Link :href="route('website.contact.create', { restaurant })" class="hover:bg-gray-100 text-lg font-medium px-4 py-2">Contact formulier</Link>
                </div>
            </div>
        </template>
    </header>
</template>
